import { ADMIN_ACCESS_LEVEL } from '~/utils';
import type { User } from '~/models/user.server';
import type { Team } from '~/models/team.server';
const DEFAULT_TEAM_AVATAR =
  'https://cdn.glitch.global/55f8497b-3334-43ca-851e-6c9780082244%2Fdefault-team-avatar.svg?1503510366819';
const DEFAULT_TEAM_COVER_IMAGE =
  'https://cdn.glitch.global/b065beeb-4c71-4a9c-a8aa-4548e266471f%2Fteam-cover-pattern.svg?v=1559853406967';

const CDN_URL = 'https://cdn.glitch.global';

export const getTeamLink = ({ url }: { url: string }) => `/team/${url}`;

export const getTeamAvatarUrl = ({
  id,
  hasAvatarImage,
  updatedAt,
  size = 'large',
}: {
  id?: number;
  hasAvatarImage: boolean;
  updatedAt?: string;
  size?: string;
}) => {
  if (!id || !updatedAt) {
    return DEFAULT_TEAM_AVATAR;
  }
  const customImage = `${CDN_URL}/team-avatar/${id}/${size}?${updatedAt}`;
  return hasAvatarImage ? customImage : DEFAULT_TEAM_AVATAR;
};

export const getTeamCoverUrl = ({
  id,
  hasCoverImage,
  updatedAt,
  size = 'large',
}: {
  id?: number;
  hasCoverImage: boolean;
  updatedAt?: string;
  size?: string;
}) => {
  if (!id || !updatedAt) {
    return DEFAULT_TEAM_COVER_IMAGE;
  }
  const customImage = `${CDN_URL}/team-cover/${id}/${size}?${updatedAt}`;
  return hasCoverImage ? customImage : DEFAULT_TEAM_COVER_IMAGE;
};

export function userIsTeamAdmin({ user, team }: { user: User; team: Team }) {
  return (
    (!!user &&
      team?.teamPermissions?.some(
        ({ userId, accessLevel }) =>
          userId === user.id && accessLevel === ADMIN_ACCESS_LEVEL,
      )) ||
    false
  );
}
